@forward "@nebular/theme/styles/theming";
@use "@nebular/theme/styles/theming" as *;
@use "@nebular/theme/styles/themes/default";
@use "@nebular/theme/styles/themes/dark";
@use "@nebular/theme/styles/themes/cosmic";
@use "@nebular/theme/styles/themes/corporate";

$nb-enable-css-custom-properties: true; // <-- enable css custom properties

$nb-themes: nb-register-theme(
  (
    color-basic-100: #ffffff,
    //  Pure White
    color-basic-200: #ffffff,
    //  Input box background Color
    color-basic-300: #ffffff,
    //  Theme Background
    //color-basic-400: #FFB266,   //  Card Border Colour
    color-basic-500: #ff5722,
    //   button
    color-basic-600: #9e9e9e,
    //  Drop Down Text
    color-basic-700: #ff5722,
    //  Deep Orange
    color-basic-800: black,
    //  Heading Colour
    color-basic-900: #ff3300,
    //  Very Deep Orange
    color-basic-1000: #ff1a00,
    //  Extremely Deep Orange
    color-basic-1100: #ff0000,

    //  Pure Deep Orange
    color-primary-100: #ffffff,
    //  Pure White
    color-primary-200: #ffffff,
    //  Drop Down Background
    color-primary-300: #ffcc99,
    //  Light Orange
    color-primary-400: #ffb266,
    //  Light-Medium Orange
    color-primary-500: #e5e5e5,
    //  Input box border
    color-primary-600: #ffffff,
    //  Drop Down Color
    color-primary-700: #ff5722,
    //  Deep Orange
    color-primary-800: black,
    // Text
    color-primary-900: #ff3300,
    //  Very Deep Orange
    color-primary-1000: #ff1a00,
    //  Extremely Deep Orange
    color-primary-1100: #ff0000,

    //  Pure Deep Orange
    button-outline-primary-hover-background-color: rgba(255, 204, 153, 0.6),
    button-outline-primary-hover-border-color: #ff5722,
    button-outline-primary-hover-text-color: #ff5722,

    button-outline-primary-background-color: rgba(255, 204, 153, 0.2),
    button-outline-primary-border-color: #ff5722,
    button-outline-primary-text-color: #ff5722,

    tabset-tab-active-text-color: #ff5722,
    tabset-tab-active-underline-color: #ff5722,

    radio-basic-inner-circle-color: #ff5722,
    radio-basic-focus-background-color: #ff5722,
    radio-basic-focus-checked-background-color: #ff5722,
    card-header-primary-background-color: #ff5722,
    badge-primary-background-color: #ff5722,
    checkbox-primary-checked-background-color: #ff5722,
    checkbox-primary-focus-checked-background-color: #ff5722,

    button-filled-basic-background-color: #ffffff,
    button-filled-basic-hover-background-color: #ffeee9,
    button-filled-basic-border-color: #ffcbba,
    button-filled-basic-hover-border-color: #ff5722,
    button-filled-basic-text-color: #ff5722,
    button-small-text-font-size: 0.6rem,

    toggle-primary-checked-background-color: #ff5722,
    toggle-primary-background-color: #ccc5c5,
    toggle-primary-checked-switcher-checkmark-color: #ff5722,
    toggle-primary-focus-checked-background-color: #ffb266,
  ),
  priyo-shop,
  default
);

[nbButton].appearance-filled.status-primary,
.appearance-filled.status-primary[nbButtonToggle] {
  background-color: var(--color-basic-500) !important;
}

.nb-theme-priyo-shop {
  --font-family-primary: Roboto !important;
  font-family: "Roboto" !important;
}

nb-checkbox.status-primary .custom-checkbox {
  border-color: var(--checkbox-primary-text-color) !important;
}

[nbButton].status-primary:hover {
  background-color: #ff3c00 !important;
  color: white !important;
  fill: white !important;
}

[nbButton].appearance-outline.status-primary:focus {
  background-color: #ff3c00 !important;
  color: white !important;
  border-color: #ff3c00 !important;
  box-shadow: #ff3c00 var(--button-outline-width) var(--button-outline-color),
    inset var(--button-outline-focus-inset-shadow-length) !important;
}

[nbButton]:focus {
  box-shadow: #ff3c00 var(--button-outline-width) var(--button-outline-color),
    inset var(--button-outline-focus-inset-shadow-length) !important;
}

#PaginationMobile nb-select .select-button {
  min-width: 4rem;
  margin-left: 1rem !important;
}

//bootstrap button
.btn-primary {
  padding-block: 0.4rem !important;
  background-color: #ff5722 !important;
  color: #fff !important;
  border: 1px solid #ff5722 !important;

  &:hover {
    background-color: #fc501c !important;
    color: #fff !important;
    border: 1px solid #fc501c !important;
  }

  &:focus {
    background-color: #fc501c !important;
    color: #fff !important;
    border: 1px solid #fc501c !important;
  }
}

//bootstrap button
.btn-primary-outline {
  padding-block: 0.4rem !important;
  background-color: transparent;
  font-weight: 500;
  border-color: #ff5722;
  color: #ff5722;

  &:hover {
    background-color: #ff5722 !important;
    color: #fff !important;
  }

  &:focus {
    background-color: #ff5722 !important;
    color: #fff !important;
  }
}

.btn-primary:disabled {
  background-color: #ffcbba !important;
  color: #ffffff;
  cursor: not-allowed;
  border: 1px solid #ffcbba !important;
}

.animation {
  animation: slideInFromTop 0.6s ease-out, fadeIn 0.5s ease-in;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation-toTop {
  animation: slideInFromBottom 0.6s ease-out, fadeIn 0.5s ease-in;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



.animation-popup {
  animation: popupFromCenter 0.5s ease-out, fadeIn 0.7s ease-in;
}

@keyframes popupFromCenter {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation-slideLeft {
  animation: slideInFromLeft 0.8s ease-out, fadeIn 0.5s ease-in;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation-slideRight {
  animation: slideInFromRight 0.8s ease-out, fadeIn 0.5s ease-in;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.custom-scrollbar {
  ::-webkit-scrollbar {
    width: 5px;
    transition: all 0.3s ease;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ff5722;
    border-radius: 5px !important;
    transition: all 0.3s ease;
  }

  ::-webkit-scrollbar-thumb:hover {
    width: 10px;
    background-color: #e64a19;
    border-radius: 10px;
    cursor: pointer;
  }
}
