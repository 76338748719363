
.mt-0 {
  margin-top: 0;
}

/* All delay classes will take 2x longer to start */
:root {
  --animate-delay: 2s;
}
.validationError::after {
  content: "** Required Field **";
  font-style: italic;
  color: var(--card-header-danger-background-color);
  font-size: 0.7rem;
}

.validationErrorMsg {
  content: "** Required Field **";
  font-style: italic;
  color: var(--card-header-danger-background-color);
  font-size: 0.7rem;
}

.validationErrorEmail::after {
  content: "** Not a valid email address **";
  font-style: italic;
  color: var(--card-header-danger-background-color);
  font-size: 0.7rem;
}

/* Max length error message */
.validationErrorMaxLength::after {
  content: "**Length cannot exceed 250 characters **";
  font-style: italic;
  color: var(--card-header-danger-background-color);
  font-size: 0.7rem;
}

.validationLength { 
  font-style: italic;
  color: var(--card-header-danger-background-color);
  font-size: 0.7rem;
}

.paging-buttons-style:hover {
  cursor: pointer;
  color: var(--color-basic-100) !important;
  background-color: var(--color-primary-600) !important;
}

.required-style::after {
  content: "*";
  color: red;
  margin-left: 0.1rem;
}
nb-option.selected {
  background-color: var(--color-primary-700) !important;
}
nb-menu .menu-item a.active,
nb-menu .menu-item a.active .menu-icon {
  color: var(--color-primary-700) !important;
}
nb-menu .menu-item a.active {
  background-color: #ffeee9;
}
[nbInput].size-medium,
nb-option-list.size-medium nb-option,
nb-select.size-medium .select-button {
  font-weight: 400 !important;
}
label {
  font-weight: 400 !important;
}
btn-color-sp {
  background-color: #f2f0ef;
}
#OrderForm .order-type-selection label {
  padding-right: 1rem !important;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}

nb-tabset .active .tab-text {
  color: #ff5722 !important;
}

nb-tabset .active .tab-link::before {
  background-color: #ff5722 !important;
}

.active-list {
  background-color: #c8e6c9;
  color: #2a602c;
}

.inactive-list {
  background-color: #ffbdc2;
  color: #8c1720;
}

.expired-list {
  background-color: #ce8e45;
  color: #3d0207;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem #ff572236;
}

.table thead th {
  background-color: #f8f8f8;
}

:focus {
  outline: none !important;
}

[nbButton].appearance-outline.status-primary,
.appearance-outline.status-primary[nbButtonToggle] {
  background-color: transparent !important;
}

[nbButton].appearance-outline.status-primary:hover,
.appearance-outline.status-primary[nbButtonToggle]:hover,
[nbButton].appearance-outline.status-primary:focus,
.appearance-outline.status-primary[nbButtonToggle]:focus {
  background-color: #ff5722 !important;
}

//Nebular design

::ng-deep nb-checkbox.status-basic .custom-checkbox.checked {
  background-color: #ff5722;
  border-color: #ff5722;
}


:host ::ng-deep nb-checkbox.status-basic .custom-checkbox.checked {
  background-color: #ff5722;
  border-color: #ff5722;
}

nb-checkbox.status-basic .custom-checkbox.checked {
  background-color: #ff5722;
  border-color: #ff5722;
}

nb-option.selected {
  background-color: #ffeee9 !important;
  color: #000 !important;
  border-bottom: 1px solid #ff5722 !important;
}

// nb-option[selected] {
//   background-color: #ffeee9 !important;
//   color: #000 !important;
// }

nb-option:hover {
  background-color: #ffeee9 !important;
  color: #000 !important;
}

nb-option:focus {
  background-color: #ffeee9 !important;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: none !important;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

a {
  background-color: transparent !important;
  cursor: pointer;
  &:hover {
    color: #ff5722 !important;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  #OrderItem {
    max-width: 70vw;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  #OrderItem {
    max-width: 70vw;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 1199px) {
  #OrderItem {
    max-width: 80vw;
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 1200px) {
  #OrderItem {
    max-width: 30vw;
  }
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}


/* Apply to all scrollbars */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ebebebb6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ebebebb6;
}

/* For horizontal scrollbar */
::-webkit-scrollbar-track-piece {
  background-color: #f1f1f1;
}

